<template>
	<div>
		<b-overlay variant="white" :show="uploadVideo" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
			<b-form-group class="grid-video">
				<div class="container-video" :class="[errorVideo ? 'container-video-error' : '']" @click="fileChange">
					<img
						class="image-mov"
						v-if="dataFile && ['mp4', 'avi', 'mkv', 'flv', 'mov', 'MOV'].includes(dataFile.extension)"
						src="https://cliply.co/wp-content/uploads/2019/07/371907120_YOUTUBE_ICON_TRANSPARENT_400.gif"
						alt=""
					/>
					<!-- <video
          class=""
          v-else
          :src="dataFile ? dataFile.base : null"
          autoplay
          muted
        ></video> -->
					<div class="skeletor-video-content">
						<p>seleccionar video</p>
					</div>
					<input
						ref="fileInput"
						type="file"
						@input="fileChange"
						class="d-none"
						accept=".mp4,.avi,.mkv,.flv,.mov,.MOV"
					/>
					<p v-if="errorVideo" style="color: red; position: absolute; bottom: -35px; left: 8px">
						El video debe ser obligatorio
					</p>
				</div>
			</b-form-group>
		</b-overlay>

		<div v-if="uploadVideo" class="container-loading-upload-battle">
			<p :class="[barValue > 50 ? 'text-white' : 'text-primary']">{{ barValue }}% cargando</p>
			<div class="bar" :style="{ width: `${barValue}%` }"></div>
		</div>
		<div :class="[uploadVideo ? 'mt-1' : '']" class="d-flex justify-content-end w-100">
			<b-button :disabled="uploadVideo" variant="primary" @click="saveVideo">Actualizar</b-button>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { uploadVideoConfrontation } from "./service.videolibrary"
// import { uploadVideoBattle } from '../reports.service'
// import videosService

export default {
	name: "UploadVideoBattle",
	props: ["item"],
	data() {
		return {
			dataFile: null,
			errorVideo: false,
			barValue: 0,
			indexParts: 0,
			parts: 0,
			uploadVideo: false,
		}
	},
	computed: {
		...mapState("header_book", ["urlBackendNode"]),
	},
	mounted() {
		// console.log("ASD: ", this.item);
	},
	methods: {
		...mapActions("video", ["A_SAVE_VIDEO_TOURNAMENT"]),
		fileChange() {
			this.$refs.fileInput.click()
			let input = this.$refs.fileInput
			let file = input.files
			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
			this.setImage(file, "video")
		},
		setImage(file, type) {
			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]

			this.errorVideo = false

			if (file && file[0]) {
				this.dataFile = {
					name: file[0].name,
					data: file[0],
					type,
					base: null,
					progress: 0,
					uploadFinished: false,
					extension: typeOfFile,
				}
				// let reader = new FileReader();

				// reader.onload = e => {
				//   //this.specimenObject.avatar = e.target.result;

				//   this.dataFile = {
				//     name: file[0].name,
				//     data: file[0],
				//     type,
				//     base: e.target.result,
				//     progress: 0,
				//     uploadFinished: false,
				//     extension: typeOfFile
				//   };
				// };
				// reader.readAsDataURL(file[0]);
			}
		},
		async saveVideo() {
			let input = this.$refs.fileInput
			let file = input.files
			if (file[0]) {
				this.uploadVideo = true

				// await this.isPreloading()

				// Subir video

				let lengthVideo = 0
				let offset = 0
				let chunkSize = 10000000
				if (file[0]) {
					while (offset < file[0].size) {
						offset += chunkSize
						lengthVideo++
					}
				}

				this.parts = lengthVideo

				console.log("AS")

				const response = await this.uploadVideoLaravel(file[0], () => {
					this.indexParts++
					console.log("index", this.indexParts)
					this.barValue = ((this.indexParts / this.parts) * 100).toFixed(2)
				})

				if (response.statusResponse == 500) {
					this.showToast(
						"danger",
						"top-right",
						"ERROR AL SUBIR VIDEO",
						"AlertCircleIcon",
						"ALGO SALIO MAL, VUELVA A INTENTAR POR FAVOR"
					)
					this.barValue = 0
					this.indexParts = 0
					this.parts = 0
					this.uploadVideo = false

					return
				}

				await uploadVideoConfrontation({
					confrontaion_id: this.item.confrontation_id,
					name: response.fileName,
					path_s3: response.url,
					video_id: this.item.video.id,
				})
				this.$emit("init")
				this.$emit("resetTable")

				this.barValue = 0
				this.indexParts = 0
				this.parts = 0
				this.uploadVideo = false

				this.showToast(
					"success",
					"top-right",
					"Video subido correctamente",
					"SuccessIcon",
					"El video se subio correctamente, pero se esta procesando."
				)
			} else {
				this.errorVideo = true
				this.showToast(
					"warning",
					"top-right",
					"Información",
					"InfoIcon",
					"Los campos deben ser obligatorios y el archivo debe ser un video."
				)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.margin-error {
	margin-top: 1.8rem;
}

.container-video {
	@media screen and (min-width: 600px) {
		height: 300px !important;
	}
}

.container-loading-upload-battle {
	background: #f3e7e9;
	width: 100%;
	height: 28px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;

	p {
		font-size: 16px;
		margin-bottom: 0;
		// color: #7367ef;
		font-weight: bold;
		z-index: 100;
	}

	.active {
		color: #fff;
	}

	.bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background: #7367ef;
	}
}

.container-video {
	height: 186px;
	width: 100%;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

	// background: red;
	video {
		// background: gold;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		border-radius: 4px;
		padding: 4px;
		// background: red;
	}

	position: relative;
	cursor: pointer;

	.skeletor-video-content {
		border-radius: 4px;
		padding: 4px;
		background: rgba(30, 30, 30, 0.5);
		position: absolute;
		top: 4px;
		left: 4px;
		right: 4px;
		bottom: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		p {
			color: #fff;
			margin-bottom: 0;
			font-size: 20px;
			font-weight: bolder;
		}
	}
}
.image-mov {
	width: 100% !important;
	height: 100% !important;
	object-fit: contain !important;
	object-position: center;
}
</style>
