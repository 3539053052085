<template>
	<div class="position-relative w-100">
		<div class="position-relative">
			<plyr :options="options" ref="plyrMain">
				<video ref="plyr" crossorigin @loadeddata="duration = $refs.plyrMain.player.duration"></video>
			</plyr>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"
import Plyr from "vue-plyr"
import "vue-plyr/dist/vue-plyr.css"

export default {
	components: {
		Plyr,
	},
	name: "VideoCloudflare",
	props: {
		uid: {
			type: String,
			default: null,
		},
		text_download: {
			type: String,
		},
		url_max_quality: {
			type: String,
		},
	},
	data() {
		return {
			options: {
				muted: true,
				hlsjsConfig: {
					debug: true,
				},
				controls: { speed: true },
				speed: { selected: 0.5, options: [0.5, 1, 1.5, 2] },
				// qualitys: { selected: 576, options: [1080, 720, 576, 480, 360, 240] },
				autoplay: true,
				i18n: {
					speed: "Velocidad",
				},
			},
			loading: false,
			endLoadeddata: false,
			progress: {
				buffered: 0,
				currentPorcentage: 0,
				paused: false,
			},
			duration: 0,
			hls: new Hls(),
		}
	},
	created() {
		this.options.speed.options = this.playbackRates
			.filter((item) => item.check)
			.map((item) => parseFloat(item.speed))
		this.options.speed.selected = parseFloat(
			this.playbackRates.find((item) => parseFloat(item.speed_default)).speed
		)
	},
	mounted() {
		const source =
			"https://customer-gsx3x8p023ja5sw6.cloudflarestream.com/" + this.uid + "/manifest/" + this.url_max_quality
		const video = this.$refs.plyr

		if (!Hls.isSupported()) {
			video.src = source
		} else {
			// const hls = new Hls()	
			this.hls.loadSource(source)
			this.hls.attachMedia(video)
			// this.hlsInstance = new Hls()
			// this.hlsInstance.loadSource(source)
			// this.hlsInstance.attachMedia(video)
		}
	},
	computed: {
		...mapState("video", ["playbackRates"]),
	},
	beforeDestroy() {
		this.$refs.plyrMain.player.off("waiting", function () {})
		this.$refs.plyrMain.player.off("playing", function () {})
		this.$refs.plyrMain.player.off("progress", function () {})
		this.$refs.plyrMain.player.off("timeupdate", function () {})
		if (this.hls) {
			this.hls.destroy()
		}
	},
}
</script>
<style>
.plyr__progress input {
	color: var(--primary) !important;
}
.plyr__volume input {
	color: var(--primary) !important;
}
.plyr__control--overlaid {
	background: var(--primary) !important;
}
.plyr__controls button:hover {
	background: var(--primary) !important;
}
.plyr {
	border-radius: 4px;
}
</style>
