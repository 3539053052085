<template>
	<div class="card">
		<div class="" v-if="!isOnlySpecimen">
			<div v-if="[2, 3, 4].includes(status)" class="mx-2 m-2">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex flex-column align-items-center align-items-sm-start justify-content-center justify-content-sm-center"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div
							class="p-md-1 flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end"
						>
							<div class="mt-1 d-flex flex-column align-items-center w-100 flex-lg-row">
								<div
									class="w-100 d-flex align-items-center justify-content-lg-end mr-lg-2"
									v-if="status == 4"
								>
									<h4 class="mb-0 mr-1" style="min-width: 9ch">Agrupar Padrillo:</h4>
									<div>
										<b-form-checkbox switch v-model="groupPadrillos" @input="init" />
									</div>
								</div>
								<div class="w-100 d-flex align-items-center justify-content-lg-end mr-lg-2">
									<h4 class="mb-0 mr-1" style="min-width: 9ch">Buscar por:</h4>
									<div>
										<b-form-checkbox v-model="statusSearch" value="specimen" unchecked-value="code">
											Padrillo/Madrilla
										</b-form-checkbox>
										<b-form-checkbox v-model="statusSearch" value="code" unchecked-value="specimen">
											Codigo
										</b-form-checkbox>
									</div>
								</div>
								<div class="w-100 w-md-auto mt-1 d-md-flex mb-md-1">
									<b-form-input
										@keyup.enter="init"
										class="w-100 w-md-auto"
										v-model="search"
										:placeholder="
											statusSearch == 'specimen'
												? 'Placa o alias / Postura'
												: 'código de la postura'
										"
										style="width: 200px"
									/>
									<button @click="init" class="btn btn-primary w-100 mt-1 mt-md-0 ml-md-1">
										Buscar
									</button>
								</div>
							</div>
							<div class="xt-search-input__paginate">
								<b-pagination
									v-model="current_page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									class="mb-0 mt-1 mt-sm-0"
									prev-class="prev-item"
									next-class="next-item"
									@input="init()"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>

		<div class="posture-b-table">
			<div class="table-responsive">
				<b-table-simple sticky-header="calc(100vh - 300px)" class="position-relative mb-0">
					<b-thead class="">
						<b-tr v-if="isActives || isFinished" class="">
							<b-th :colspan="isOnlySpecimen ? '2' : '3'" class="bg-white border-0"></b-th>
							<b-th colspan="4" class="border-white bg-white p-0 m-0">
								<div class="text-white text-center box-primary">HUEVOS</div>
							</b-th>
							<b-th colspan="5" class="border-white bg-white p-0 m-0">
								<div class="text-white text-center box-primary">POLLOS</div>
							</b-th>
							<b-th colspan="4" class="bg-white border-0"></b-th>
						</b-tr>
						<b-tr
							class="text-center s-header-cell"
							:class="[[2, 3, 4].includes(status) ? 'sticky-table' : '']"
						>
							<template v-for="(item, key) in headTable">
								<b-th class="p-0" :key="key" v-if="item.visible" :class="item.class">
									<div class="p-1 d-flex align-items-center justify-content-center">
										<img class="icon-head-posturas" :src="item.image" alt="" />
										<p class="mb-0">{{ item.text }}</p>
									</div>
								</b-th>
							</template>
						</b-tr>
					</b-thead>

					<b-tbody>
						<template v-if="posturas.length > 0">
							<b-tr
								v-for="(data, index) in posturas"
								class="text-center col-span"
								:key="`posturas-${index}`"
								:class="evenColspan(data.check, index)"
							>
								<template v-if="!groupPadrillos && !!!specimenId ? true : data.check">
									<b-td
										:rowspan="!groupPadrillos ? 1 : data.repeat"
										class="text-center s-padrillo-card"
									>
										<SpecimenPlate
											:specimen="{
												plate: data.padrillo.plate,
												alias: data.padrillo.alias,
												id: data.padrillo.id,
												thumb: data.padrillo.thumb,
												image: data.padrillo.image,
											}"
										/>
										<div>
											<p class="mb-0 mt-1">
												TOT: {{ data.padrillo.pa }} |
												<span
													@click="
														status == 1 &&
															data.padrillo.pp > 0 &&
															openModalByIdSpecimenPending(data.padrillo)
													"
													:class="[
														status == 1 && data.padrillo.pp > 0
															? 'font-weight-bolder cursor-pointer'
															: '',
													]"
												>
													ACT: {{ data.padrillo.pp }}
												</span>
											</p>
										</div>

										<div>
											<p
												v-if="data.padrillo.fertilidad_season > 0"
												class="mb-0 font-weight-bolder"
											>
												FA:
												<span class="font-weight-bolder">
													{{ `${data.padrillo.fertilidad.toFixed(1)}%` }}
												</span>
												<span>|</span>
												FT:
												<span class="font-weight-bolder" v-if="data.padrillo.fertilidad_season">
													{{ `${data.padrillo.fertilidad_season.toFixed(1)}%` }}
												</span>
											</p>
										</div>
										<div
											v-b-tooltip.hover="`Hijos vivos por temporada`"
											v-if="data.padrillo.chicks_alive"
										>
											HV : {{ data.padrillo.chicks_alive }}
										</div>
										<div v-b-tooltip.hover="`Tiempo en postura`" v-if="data.end_rest">
											TP : {{ data.end_rest | untilMonthDay }}
										</div>
										<div class="d-flex justify-content-center mt-25">
											<!-- <b class="mr-25">Tipo inseminacion:</b> -->
											<div class="d-inline-block">
												<ChangeSpecimenEggEater
													dashboard
													:info="data.padrillo"
													:type="'padrillo'"
													:disabled="true"
												/>
											</div>
										</div>
										<div class="mt-1">
											<div>
												<span>
													<template v-if="[0, 1].includes(data.padrillo.in_galpon)">
														<feather-icon
															size="18"
															v-b-tooltip.hover.top="
																data.padrillo.in_galpon === 1
																	? 'Disponible'
																	: 'No disponible'
															"
															:class="{
																'text-danger': data.padrillo.in_galpon === 0,
																'text-success': data.padrillo.in_galpon === 1,
															}"
															icon="CheckCircleIcon"
														></feather-icon>
													</template>
												</span>
												<span style="font-size: 13px" v-if="data.padrillo.status_name">
													({{ data.padrillo.status_name }})
												</span>
											</div>
											<b-button
												class="btn-icon ml-05"
												size="sm"
												variant="flat-warning"
												@click="
													;(testModal.info = { ...data, _parent: 'p' }),
														(testModal.show = !testModal.show)
												"
												v-if="[2, 3].includes(status)"
											>
												<feather-icon icon="EditIcon" />
											</b-button>
										</div>
									</b-td>

									<b-td class="s-bordered-cell">
										<SpecimenPlate
											:specimen="{
												plate: data.madrilla.plate,
												alias: data.madrilla.alias,
												id: data.madrilla.id,
												thumb: data.madrilla.thumb,
												image: data.madrilla.image,
											}"
										/>
										<div>
											<p class="mb-0 mt-1">TOT: {{ data.madrilla.pa }}</p>
										</div>
										<div v-if="data.madrilla.fertilidad_season > 0">
											<p class="mb-0 font-weight-bolder">
												FA:
												<span class="font-weight-bolder">
													{{ `${data.madrilla.fertilidad.toFixed(1)}%` }}
												</span>
												<span>|</span>
												FT:
												<span class="font-weight-bolder" v-if="data.madrilla.fertilidad_season">
													{{ `${data.madrilla.fertilidad_season.toFixed(1)}%` }}
												</span>
											</p>
										</div>
										<div
											v-b-tooltip.hover="`Hijos vivos por temporada`"
											v-if="data.madrilla.chicks_alive"
										>
											HV : {{ data.madrilla.chicks_alive }}
										</div>
										<div class="mt-1">
											<div>
												<span>
													<template v-if="[0, 1].includes(data.madrilla.in_galpon)">
														<feather-icon
															size="18"
															v-b-tooltip.hover.top="
																data.madrilla.in_galpon === 1
																	? 'Disponible'
																	: 'No disponible'
															"
															:class="{
																'text-danger': data.madrilla.in_galpon === 0,
																'text-success': data.madrilla.in_galpon === 1,
															}"
															icon="CheckCircleIcon"
														></feather-icon>
													</template>
												</span>
												<span style="font-size: 13px" v-if="data.madrilla.status_name">
													({{ data.madrilla.status_name }})
												</span>
											</div>
											<b-button
												class="btn-icon ml-05"
												size="sm"
												variant="flat-warning"
												@click="
													;(testModal.info = { ...data, _parent: 'm' }),
														(testModal.show = !testModal.show)
												"
												v-if="[2, 3].includes(status)"
											>
												<feather-icon icon="EditIcon" />
											</b-button>
										</div>
									</b-td>
								</template>

								<template v-else-if="categoryId == 1">
									<td class="s-bordered-cell">
										<SpecimenPlate
											:specimen="{
												plate: data.madrilla.plate,
												alias: data.madrilla.alias,
												id: data.madrilla.id,
												thumb: data.madrilla.thumb,
												image: data.madrilla.image,
											}"
										/>
										<div>
											<p class="mb-0 mt-1">TOT: {{ data.madrilla.pa }}</p>
										</div>

										<div v-if="data.madrilla.fertilidad_season > 0">
											<p class="mb-0 font-weight-bolder">
												FA:
												<span class="font-weight-bolder">
													{{ `${data.madrilla.fertilidad.toFixed(1)}%` }}
												</span>
												<span>|</span>
												FT:
												<span v-if="data.madrilla.fertilidad_season">
													{{ `${data.madrilla.fertilidad_season.toFixed(1)}%` }}
												</span>
											</p>
										</div>
										<div
											v-b-tooltip.hover="`Hijos vivos por temporada`"
											v-if="data.madrilla.chicks_alive"
										>
											HV : {{ data.madrilla.chicks_alive }}
										</div>
										<div class="mt-1">
											<div>
												<span>
													<template v-if="[0, 1].includes(data.madrilla.in_galpon)">
														<feather-icon
															size="18"
															v-b-tooltip.hover.top="
																data.madrilla.in_galpon === 1
																	? 'Disponible'
																	: 'No disponible'
															"
															:class="{
																'text-danger': data.madrilla.in_galpon === 0,
																'text-success': data.madrilla.in_galpon === 1,
															}"
															icon="CheckCircleIcon"
														></feather-icon>
													</template>
												</span>
												<span style="font-size: 13px" v-if="data.madrilla.status_name">
													({{ data.madrilla.status_name }})
												</span>
											</div>
											<b-button
												class="btn-icon ml-05"
												size="sm"
												variant="flat-warning"
												@click="
													;(testModal.info = { ...data, _parent: 'm' }),
														(testModal.show = !testModal.show)
												"
												v-if="[2, 3].includes(status)"
											>
												<feather-icon icon="EditIcon" />
											</b-button>
										</div>
									</td>
								</template>

								<template v-else>
									<td class="s-bordered-cell">
										<SpecimenPlate
											:specimen="{
												plate: data.padrillo.plate,
												alias: data.padrillo.alias,
												id: data.padrillo.id,
												thumb: data.padrillo.thumb,
												image: data.padrillo.image,
											}"
										/>
										<div>
											<p class="mb-0 mt-1">
												TOT: {{ data.padrillo.pa }} | ACT: {{ data.padrillo.pp }}
											</p>
										</div>
									</td>
								</template>

								<b-td class="s-bordered-cell">
									<p
										class="mb-0"
										:class="[
											isActives || isFinished
												? 'text-primary font-weight-bold cursor-pointer'
												: '',
										]"
										@click="openModal(data)"
									>
										{{ data.postura.code }}
									</p>
									<div v-if="(isActives || isFinished) && data.cintillos" class="text-center">
										<div class="d-flex align-items-center justify-content-center pl-50">
											<CombChickenlegsCintillos
												v-if="data.cintillos && data.cintillos.id"
												:postura="{
													chickenleg_wich_leg: data.cintillos.wich_leg,
													chickenleg_qty_cintillos: data.cintillos.qty_cintillos,
													cintillo_color: data.cintillos.hex_1,
													cintillo_description: data.cintillos.description_1,
													second_color_color: data.cintillos.hex_2,
													second_color_description: data.cintillos.description_2,
													total_minus_infertile_eggs: 1,
												}"
												:chickenleg_wich_leg="1"
												is_in_posturas
											/>
											<CombChickenlegsCintillos
												v-if="data.cintillos && data.cintillos.id"
												:postura="{
													chickenleg_wich_leg: data.cintillos.wich_leg,
													chickenleg_qty_cintillos: data.cintillos.qty_cintillos,
													cintillo_color: data.cintillos.hex_1,
													cintillo_description: data.cintillos.description_1,
													second_color_color: data.cintillos.hex_2,
													second_color_description: data.cintillos.description_2,
													total_minus_infertile_eggs: 1,
												}"
												is_in_posturas
												:chickenleg_wich_leg="2"
											/>
										</div>

										<p
											class="mb-0 font-small-2 style-liberado"
											v-text="
												data.cintillos.status == 'FINALIZADA'
													? 'LIBERADA'
													: data.cintillos.status
											"
										></p>
									</div>
								</b-td>

								<b-td v-if="isActives || isByFinish || isFinished">
									<b-button
										@click="openRegisterEggsPostura(data)"
										variant="outline-primary"
										:disabled="data.reg * 1 === 0"
									>
										{{ data.reg }}
									</b-button>
								</b-td>

								<b-td v-if="isActives || isByFinish || isFinished">{{ data.ing }}</b-td>

								<b-td v-if="isActives || isByFinish || isFinished">{{ data.inf }}</b-td>

								<b-td v-if="isActives || isByFinish || isFinished">{{ data.que }}</b-td>

								<b-td v-if="isActives || isByFinish || isFinished">{{ data.nac }}</b-td>

								<b-td v-if="isActives || isByFinish || isFinished">{{ data.no_n }}</b-td>

								<b-td v-if="isActives || isByFinish || isFinished">
									<span
										class="font-weight-bolder text-primary clickable"
										@click="
											;(childsAliveModal.info = data),
												(childsAliveModal.show = !childsAliveModal.show)
										"
										v-if="Number(data.viv) > 0"
									>
										{{ data.viv }}
									</span>
									<span v-else>
										{{ data.viv }}
									</span>
								</b-td>

								<b-td v-if="isActives || isByFinish || isFinished" class="">
									<span
										class="font-weight-bolder text-primary clickable"
										@click="
											;(childsDeadModal.info = data),
												(childsDeadModal.show = !childsDeadModal.show)
										"
										v-if="Number(data.deads) > 0"
									>
										{{ data.deads }}
									</span>
									<span v-else>
										{{ data.deads }}
									</span>
								</b-td>

								<b-td v-if="isActives || isByFinish || isFinished" class="s-bordered-cell">
									{{ data.depu }}
								</b-td>

								<b-td class="s-bordered-cell" v-if="isByFinish">
									<template v-if="typeof data.last_register == 'object'">
										<p class="mb-0">{{ data.last_register.fullname }}</p>
										<span>{{ data.last_register.created_at | myGlobalDayShort }}</span>
									</template>
									<template v-else>
										<p class="mb-0">{{ JSON.parse(data.last_register).fullname }}</p>
										<span>{{ JSON.parse(data.last_register).created_at | myGlobalDayShort }}</span>
									</template>
								</b-td>

								<b-td class="s-bordered-cell">
									<p class="mb-0">{{ data.user.name }}</p>
									<span>{{ data.user.created_at | myGlobalDayShort }}</span>
								</b-td>

								<b-td class="s-bordered-cell" v-if="[2, 3].includes(status)">
									{{ data.active_date | myGlobalDayWithHour }}
								</b-td>

								<b-td
									v-if="(isPending || isActives || isByFinish) && !isActives && !isInvitado"
									class="s-bordered-cell"
								>
									<div>
										<FeatherIcon
											@click="openModalActivePostura(data)"
											icon="CheckCircleIcon"
											class="mr-1 text-success cursor-pointer"
											size="24"
										/>
										<FeatherIcon
											@click="openModalCancelPostura(data)"
											icon="XCircleIcon"
											class="text-danger cursor-pointer"
											size="24"
										/>
										<FeatherIcon
											icon="RepeatIcon"
											class="ml-1 text-warning cursor-pointer"
											v-b-tooltip.hover="`Cambiar padrillo`"
											size="24"
											@click="
												;(changePadrilloModal.info = data),
													(changePadrilloModal.show = !changePadrilloModal.show)
											"
										/>
									</div>
								</b-td>

								<b-td v-if="isActives && !isInvitado" class="s-bordered-cell">
									<div>
										<FeatherIcon
											@click="openModalFinishedPostura(data)"
											icon="LogOutIcon"
											class="text-success cursor-pointer"
											size="24"
										/>
									</div>
								</b-td>

								<b-td v-if="isFinished && isActives" class="s-bordered-cell">
									<p class="mb-0">{{ data.user.name }}</p>
									<span>{{ data.user.created_at | myGlobalDayShort }}</span>
								</b-td>
								<b-td v-if="!isCancel && !isActives && data.user_change_status" class="s-bordered-cell">
									<p class="mb-0">
										{{ data.user_change_status ? data.user_change_status.user : null }}
									</p>
									<span>{{ data.user_change_status.date | myGlobalDayShort }}</span>
									<!--  -->
								</b-td>

								<b-td v-if="data.deleted_by && data.deleted_by.name" class="s-bordered-cell">
									<p class="mb-0">{{ data.deleted_by ? data.deleted_by.name : null }}</p>
									<span>{{ data.deleted_by.created_at | myGlobalDayShort }}</span>
								</b-td>
							</b-tr>
						</template>
						<template v-else>
							<b-tr>
								<b-td colspan="12" class="text-center">
									<p class="mb-1 mt-1 font-weight-bolder">NO HAY POSTURAS A MOSTRAR</p>
								</b-td>
							</b-tr>
						</template>
					</b-tbody>
					<b-tfoot v-if="[2, 3, 4].includes(status)">
						<b-tr>
							<b-th class="p-1 text-center" v-if="posturas.length && posturas[0].hasOwnProperty('check')">
								{{
									posturas.reduce(function (previousValue, currentValue) {
										return previousValue + (currentValue.check ? 1 : 0)
									}, 0)
								}}
							</b-th>
							<b-th class="p-1"></b-th>
							<b-th class="p-1 text-center">TOTAL</b-th>
							<b-th class="p-1 text-center">
								{{ sumEggs(posturas, "reg") }}
							</b-th>
							<b-th class="p-1 text-center">
								{{ sumEggs(posturas, "ing") }}
							</b-th>
							<b-th class="p-1 text-center">
								{{ sumEggs(posturas, "inf") }}
							</b-th>
							<b-th class="p-1 text-center">
								{{ sumEggs(posturas, "que") }}
							</b-th>
							<b-th class="p-1 text-center">
								{{ sumEggs(posturas, "nac") }}
							</b-th>
							<b-th class="p-1 text-center">
								{{ sumEggs(posturas, "no_n") }}
							</b-th>
							<b-th class="p-1 text-center">
								{{ sumEggs(posturas, "viv") }}
							</b-th>
							<b-th class="p-1 text-center">
								{{ sumEggs(posturas, "deads") }}
							</b-th>
							<b-th class="p-1 text-center">
								{{ sumEggs(posturas, "depu") }}
							</b-th>
							<b-th colspan="4" class="p-1 text-center"></b-th>
						</b-tr>
					</b-tfoot>
				</b-table-simple>
			</div>
		</div>

		<div class="d-flex flex-column align-items-start p-2">
			<h4>Leyenda:</h4>
			<div class="legend-postura-main">
				<p>
					TOT:
					<span>Posturas totales</span>
				</p>
				<p>
					ACT:
					<span>Posturas actuales</span>
				</p>
				<p>
					FA:
					<span>Fertilidad acumulada</span>
				</p>
				<p>
					FT:
					<span>Fertilidad por temporada</span>
				</p>
			</div>
		</div>

		<b-modal
			v-model="modalViewByPostura.visible"
			:title="modalViewByPostura.title"
			size="xmd"
			hide-footer
			body-class="m-0 p-2"
		>
			<PosturasListViewById v-if="modalViewByPostura.postura_id" :postura_id="modalViewByPostura.postura_id" />
		</b-modal>

		<b-modal
			v-model="modalActivatePostura.visible"
			:title="modalActivatePostura.title"
			body-class="m-0 p-2"
			@ok="activatePostura"
		>
			<div class="container-checkbox">
				<b-form-checkbox v-model="methodPostura" :value="1" :unchecked-value="2">
					<p class="font-small-4">Natural</p>
				</b-form-checkbox>
				<b-form-checkbox v-model="methodPostura" :value="2" :unchecked-value="1">
					<p class="font-small-4">Artificial</p>
				</b-form-checkbox>
			</div>
		</b-modal>

		<b-modal
			v-model="modalCancelPostura.visible"
			:title="modalCancelPostura.title"
			body-class="m-0 p-2"
			@ok="cancelPostura"
		>
			<div>
				<p class="">Ingresa un comentario</p>
				<b-form-textarea
					v-model="textCancelPostura"
					placeholder="Enter something..."
					rows="3"
					max-rows="6"
				></b-form-textarea>
			</div>
		</b-modal>

		<b-modal
			v-model="modalFinishedPostura.visible"
			:title="modalFinishedPostura.title"
			body-class="m-0 p-2"
			@ok="finishedPostura"
		>
			<div>
				<b-form-group>
					<v-select
						v-model="status_madrilla"
						:options="arrayReasons"
						:clearable="false"
						transition=""
						placeholder="Seleccione un motivo"
						:reduce="(option) => option.id"
						label="description"
					/>
				</b-form-group>
			</div>
		</b-modal>

		<ListRegisterEggs
			:dataEggs="dataEggs"
			v-if="controllerRegisterEggsPostura"
			@hidden="controllerRegisterEggsPostura = false"
		/>

		<b-modal
			@hide="hideModalUpdateStatus"
			hide-footer
			v-model="modalUpdateStatus.show"
			:title="modalUpdateStatus.title"
		>
			<div>
				<validation-observer ref="form" class="mb-1">
					<div class="w-100 mb-1">
						<ValidationProvider rules="required" v-slot="{ errors }" class="w-100 mb-1">
							<b-form-group class="mb-0" label="Peso (en Kilogramos)">
								<b-input-group size="lg" prepend="KG">
									<b-form-input
										class="mb-0"
										type="number"
										v-model="modalUpdateStatus.weight"
										placeholder="Agregar peso"
										:invalid-feedback="errors[0]"
										:state="errors[0] ? false : null"
									></b-form-input>
								</b-input-group>
							</b-form-group>
							<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
						</ValidationProvider>
					</div>

					<div class="w-100 mb-1" v-if="false">
						<ValidationProvider rules="required" v-slot="{ errors }" class="w-100 mb-1">
							<b-form-group label="Responsable" class="w-100 mb-0">
								<v-select
									class="w-100"
									v-model="modalUpdateStatus.user_id"
									:options="modalUpdateStatus.users"
									:clearable="false"
									label="name"
									:reduce="(option) => option.id"
									transition=""
									:placeholder="`Buscar responsable`"
									:invalid-feedback="errors[0]"
									:state="errors[0] ? false : null"
									:class="[errors[0] ? 'border-danger' : '']"
								>
									<template slot="no-options">Sin cintillo alas encontradas</template>

									<template slot="option" slot-scope="option">
										<div class="d-center">
											{{ option.name }}
										</div>
									</template>
									<template slot="selected-option" slot-scope="option">
										<div class="selected d-center">
											{{ option.name }}
										</div>
									</template>
								</v-select>
							</b-form-group>
							<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
						</ValidationProvider>
					</div>

					<div class="mb-50">
						<b-form-checkbox
							switch
							@input="hideCommentModalUpdateStatus"
							v-model="modalUpdateStatus.show_comment"
						>
							{{ modalUpdateStatus.show_comment ? "Description" : "Description" }}
						</b-form-checkbox>
					</div>

					<b-form-group v-show="modalUpdateStatus.show_comment" class="" label="Descripcion (Opcional)">
						<b-form-textarea
							class="mb-0"
							type="number"
							v-model="modalUpdateStatus.description"
							placeholder="Agregar Descripcion"
						></b-form-textarea>
					</b-form-group>
				</validation-observer>
			</div>
			<div class="d-flex justify-content-end mt-1">
				<button
					@click="
						updateSpecimenPostura(
							modalUpdateStatus.id,
							modalUpdateStatus.status,
							modalUpdateStatus.weight,
							modalUpdateStatus.index
						)
					"
					class="btn btn-primary"
				>
					Actualizar
				</button>
			</div>
		</b-modal>

		<b-modal
			hide-footer
			body-class="p-0 m-0"
			v-model="modalViewPosturasPending.show"
			:title="modalViewPosturasPending.title"
			size="xl"
		>
			<PosturaListPendingByIdSpecimen
				:categoryId="1"
				:isOnlySpecimen="true"
				:specimenId="modalViewPosturasPending.id"
				:statusFromModal="2"
			/>
		</b-modal>
		<ChangeParentStatus
			:show="testModal.show"
			:info="testModal.info"
			@closing="testModal.show = false"
			@refresh="init(), $emit('refresh-counters')"
		/>
		<ChangePosturaPadrillo
			:show="changePadrilloModal.show"
			:info="changePadrilloModal.info"
			@closing="changePadrilloModal.show = false"
			@refresh="init"
		/>
		<PosturaChildsAlive
			:show="childsAliveModal.show"
			:info="childsAliveModal.info"
			@closing="childsAliveModal.show = false"
		/>
		<PosturaChildsDead
			:show="childsDeadModal.show"
			:info="childsDeadModal.info"
			@closing="childsDeadModal.show = false"
		/>
	</div>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import PosturasListViewById from "./PosturasListViewById.vue"
import posturaService from "@/services/posturas.service"
import CombChickenlegsCintillos from "../lotes/components/CombChickenlegsCintillos.vue"
import reasonsService from "@/services/reasons.service"
import ListRegisterEggs from "./modals/ListRegisterEggs.vue"
import { mapState } from "vuex"
import Toggle from "@/components/commons/utilities/Toggle.vue"
import specimensService from "@/services/specimens.service"
import moment from "moment"
import utilitiesService from "@/services/utilities.service"
import PosturaListPendingByIdSpecimen from "./PosturaListPendingByIdSpecimen.vue"
import ChangeParentStatus from "@/views/amg/encaste/posturas/modals/ChangeParentStatus.vue"
import ChangePosturaPadrillo from "./modals/ChangePosturaPadrillo.vue"
import PosturaChildsAlive from "./modals/PosturaChildsAlive.vue"
import PosturaChildsDead from "./modals/PosturaChildsDead.vue"
import specimenService from "@/services/specimens.service"
import ChangeSpecimenEggEater from "@/views/amg/specimens/ChangeSpecimenEggEater.vue"

export default {
	name: "PosturasListByStatus",
	components: {
		SpecimenPlate,
		PosturasListViewById,
		CombChickenlegsCintillos,
		ListRegisterEggs,
		Toggle,
		PosturaListPendingByIdSpecimen,
		ChangeParentStatus,
		ChangePosturaPadrillo,
		PosturaChildsAlive,
		PosturaChildsDead,
		ChangeSpecimenEggEater,
	},
	props: {
		id_padrillo: {
			type: Number,
			required: null,
		},
		id_madrilla: {
			type: Number,
			required: null,
		},
		isOnlySpecimen: {
			type: Boolean,
			default: false,
		},
		specimenId: {
			type: [Number, String],
			default: null,
		},
		categoryId: {
			type: [Number, String],
			default: 1,
		},
		statusFromModal: {
			type: [Number, String],
			required: true,
		},
	},
	async mounted() {
		this.$root.$on("reloader-posturas-list", async () => {
			await this.refreshTotalPosturas()
		})

		if ([2, 3].includes(this.status)) {
			// await this.getReasonsByModule()
			await this.getSpecimenStatusTrackings()
		}

		this.reloadHeadersTable()

		await this.init()

		this.isPreloading(false)
	},
	destroyed() {
		this.$root.$off("reloader-posturas-list")
	},
	computed: {
		...mapState("encastePosturas", ["year", "start_date", "end_date"]),
		status() {
			return this.$route.meta.status || this.statusFromModal
		},
		isPending() {
			return [1].includes(this.status) ? true : false
		},
		isActives() {
			return [2, 3].includes(this.status) ? true : false
		},
		isFinished() {
			return [4].includes(this.status) ? true : false
		},
		isByFinish() {
			return [3].includes(this.status) ? true : false
		},
		isCancel() {
			return [5].includes(this.status) ? true : false
		},
		sumEggs: () => (posturas, key) => {
			return posturas.reduce(function (previousValue, currentValue) {
				return previousValue + parseInt(currentValue[key])
			}, 0)
		},
		evenColspan() {
			let count = 0
			return function (check, index) {
				if (this.specimenId) {
					return index % 2 === 0 ? "col-odd" : "col-even"
				}

				if (check) count += 1

				return count % 2 === 0 ? "col-even" : "col-odd"
			}
		},
		validateCategory() {
			return function (category) {
				return this.isOnlySpecimen ? this.categoryId === category : true
			}
		},
	},
	watch: {
		status(newValue, oldValue) {
			if (this.status == 4) {
				this.groupPadrillos = false
			} else {
				this.groupPadrillos = true
			}
			//
			this.init()
			this.reloadHeadersTable()
		},
	},
	data() {
		const isPadrilloCategory = this.isOnlySpecimen ? this.categoryId === 1 : true
		const isMadrillaCategory = this.isOnlySpecimen ? this.categoryId === 2 : true

		return {
			statusSearch: "specimen",
			methodPostura: 1,
			search: null,
			headTable: [
				//
				{ text: "PADRILLO", value: "padrillo", visible: isMadrillaCategory, class: "s-bordered-cell" },
				{ text: "MADRILLA", value: "madrilla", visible: isPadrilloCategory, class: "s-bordered-cell" },
				{ text: "CODIGO", value: "codigo", visible: true, class: "s-bordered-cell" },
				{
					text: "REG",
					value: "reg",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "ING",
					value: "ing",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-2.svg",
				},
				{
					text: "INF",
					value: "inf",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-3.svg",
				},
				{
					text: "QUE",
					value: "que",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-4.svg",
				},
				{
					text: "NAC",
					value: "nac",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-5.svg",
				},
				{
					text: "NON",
					value: "no_n",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-6.svg",
				},
				{
					text: "VIV",
					value: "viv",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-7.svg",
				},
				{
					text: "MUE",
					value: "mue",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-8.svg",
					class: "",
				},
				{
					text: "DEP",
					value: "dep",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-8.svg",
					class: "s-bordered-cell",
				},
				{
					text: "Último registro",
					value: "register_by",
					visible: [3].includes(this.status) ? true : false,
					class: "s-bordered-cell",
				},
				{
					text: "CREADO POR",
					value: "creado_por",
					visible: true,
					class: "s-bordered-cell",
				},
				{
					text: "ACCIONES",
					value: "acciones",
					visible: [3, 4, 5].includes(this.status) ? false : true,
					class: "s-bordered-cell",
				},
				{
					text: "FINALIZAR",
					value: "finalizar",
					visible: [1, 2, 4, 5].includes(this.status) ? false : true,
					class: "s-bordered-cell",
				},
				{
					text: "FINALIZADO POR",
					value: "completedBy",
					visible: [1, 2, 3, 5].includes(this.status) ? false : true,
					class: "s-bordered-cell",
				},
				{
					text: "ANULADO POR",
					value: "canceledBy",
					visible: [1, 2, 3, 4].includes(this.status) ? false : true,
					class: "s-bordered-cell",
				},
			],
			posturas: [],
			modalViewByPostura: {
				title: "Detalle de postura",
				visible: false,
				postura_id: null,
			},
			modalActivatePostura: {
				title: "Activar postura",
				visible: false,
				postura_id: null,
			},
			modalCancelPostura: {
				title: "Cancelar postura",
				visible: false,
				postura_id: null,
			},
			modalFinishedPostura: {
				title: "Finalizar postura",
				visible: false,
				postura_id: null,
			},
			textCancelPostura: null,
			arrayReasons: [],
			status_madrilla: null,
			reason_id: null,
			start_page: 1,
			to_page: 1,
			total_data: 1,
			current_page: 1,
			perpage: 50,
			count: 0,
			controllerRegisterEggsPostura: false,
			dataEggs: null,
			modalUpdateStatus: {
				show: false,
				title: null,
				id: null,
				status: null,
				weight: null,
				index: null,
				users: [],
				user_id: null,
				description: null,
				isPadrillo: false,
				show_comment: false,
			},
			modalViewPosturasPending: {
				show: false,
				title: "Posturas",
				id: null,
			},
			testModal: {
				show: false,
				info: {},
			},
			changePadrilloModal: {
				show: false,
				info: {},
			},
			childsAliveModal: { show: false, info: {} },
			childsDeadModal: { show: false, info: {} },
			groupPadrillos: true,
		}
	},
	methods: {
		async getSpecimenStatusTrackings() {
			this.arrayReasons = await specimenService.getAllStatusSpecimens(2)
			this.arrayReasons = this.arrayReasons.filter((item) => item.in_galpon == 1)
			this.arrayReasons = this.arrayReasons.filter((item) => [15, 12, 9, 6].includes(item.id))
		},
		openModal(data) {
			if (this.isActives || this.isFinished) {
				this.modalViewByPostura.visible = true
				this.modalViewByPostura.title = `${data.postura.code}`
				this.modalViewByPostura.postura_id = data.postura.id
			}
		},
		openModalActivePostura(data) {
			this.modalActivatePostura.visible = true
			this.modalActivatePostura.title = `Activar postura ${data.postura.code}`
			this.modalActivatePostura.postura_id = data.postura.id
		},
		openModalCancelPostura(data) {
			this.modalCancelPostura.visible = true
			this.modalCancelPostura.title = `Cancelar postura ${data.postura.code}`
			this.modalCancelPostura.postura_id = data.postura.id
		},
		changePadrillo(data) {
			console.log("change", data)
		},
		async openModalFinishedPostura(data) {
			console.log(data)
			console.log(data.padrillo.pp)
			let text
			if (data.padrillo.pp == 1) {
				text = `Al no tener mas posturas activas, se cambiara el estado del padrillo ${
					data.padrillo.alias || data.padrillo.plate
				} a DESCANSO`
			} else {
				text = `La postura de ${data.padrillo.alias || data.padrillo.plate} se pasara a FINALIZADAS`
			}
			const { isConfirmed } = await this.showConfirmSwal({
				text,
			})
			if (!isConfirmed) return
			this.modalFinishedPostura.visible = true
			this.modalFinishedPostura.title = `Finalizar postura ${data.postura.code}`
			this.modalFinishedPostura.postura_id = data.postura.id
		},
		async getReasonsByModule() {
			const data = await reasonsService.getByModule(2)
			this.arrayReasons = data.map((item) => {
				return {
					id: item.id,
					label: item.reason,
				}
			})
		},
		async init() {
			this.$route.name != "detalle-ejemplar" && this.isPreloading()
			if ([1, 5].includes(this.status)) {
				await this.initPending()
			} else {
				await this.initActives()
			}
			this.isPreloading(false)
		},
		async initPending() {
			this.posturas = []
			if (this.specimenId) {
				this.posturas = await posturaService.fetchPostureBySpecimenId({
					specimen_id: this.specimenId,
					category_id: this.categoryId,
					status_in: this.status,
					start_date: this.start_date,
					end_date: this.end_date,
				})
			} else {
				this.posturas = await posturaService.getPosturasByStatus({
					statusIn: this.status,
					typeSearchIn: this.statusSearch == "specimen" ? 1 : 2,
					start_date: this.start_date,
					end_date: this.end_date,
					pageIn: this.current_page,
					perPageIn: this.perpage,
				})
			}
		},
		async initActives() {
			this.posturas = []
			if (this.specimenId) {
				this.posturas = await posturaService.fetchPostureWithEggsBySpecimenId({
					specimen_id: this.specimenId,
					category_id: this.categoryId,
					status: this.status,
					dateIn: this.start_date,
					dateFn: this.end_date,
				})
			} else {
				console.log(this.status)
				let isGrouped = 1
				if (this.status == 4) {
					isGrouped = this.groupPadrillos ? 1 : 0
				}
				const { data, from, last_page, per_page, to, total } = await posturaService.getPosturasByStatusAndEggs({
					statusIn: this.status,
					typeSearchIn: this.statusSearch == "specimen" ? 1 : 2,
					start_date: this.start_date,
					end_date: this.end_date,
					pageIn: this.current_page,
					perPageIn: this.perpage,
					searchIn: this.search,
					grouped: isGrouped,
				})

				this.posturas = data.map((item) => {
					let cintillos = {
						...item.cintillos,
						chickenleg_wich_leg:
							item.cintillos &&
							(item.cintillos.wich_leg == "IZQUIERDA" ? 1 : item.cintillos.wich_leg == "DERECHA" ? 2 : 3),
					}
					return {
						...item,
						cintillos: item.cintillos ? cintillos : null,
						user_change_status: item.user_change_status ? JSON.parse(item.user_change_status) : null,
						madrilla: {
							...item.madrilla,
							checkActive: item.madrilla.status == 1 ? true : false,
						},
						padrillo: {
							...item.padrillo,
							checkActive: item.padrillo.status == 1 ? true : false,
						},
						//
					}
				})
				this.perpage = per_page
				// this.current_page = from;
				this.total_data = total
				this.to_page = to
				this.start_page = from
			}
		},
		async cancelPostura() {
			const resp = await posturaService.cancelPostura({
				postura_id: this.modalCancelPostura.postura_id,
				textTrackingIn: this.textCancelPostura,
			})
			await this.refreshTotalPosturas()
		},
		async activatePostura() {
			const resp = await posturaService.activatePostura({
				postura_id: this.modalActivatePostura.postura_id,
				method_id: this.methodPostura,
			})
			await this.refreshTotalPosturas()
		},
		async finishedPostura() {
			const resp = await posturaService.finishPostura({
				postura_id: this.modalFinishedPostura.postura_id,
				reason_text: this.reason_id,
				status_madrilla_id: this.status_madrilla,
			})
			await this.refreshTotalPosturas()
		},
		async refreshTotalPosturas() {
			// debugger;
			await this.init()
			this.$emit("refresh-counters")
		},
		reloadHeadersTable() {
			const isPadrilloCategory = this.isOnlySpecimen ? this.categoryId === 1 : true
			const isMadrillaCategory = this.isOnlySpecimen ? this.categoryId === 2 : true
			this.headTable = [
				{ text: "PADRILLO", value: "padrillo", visible: isMadrillaCategory, class: "s-bordered-cell" },
				{ text: "MADRILLA", value: "madrilla", visible: isPadrilloCategory, class: "s-bordered-cell" },
				{ text: "CODIGO", value: "codigo", visible: true, class: "s-bordered-cell" },
				{
					text: "REG",
					value: "reg",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "ING",
					value: "ing",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-2.svg",
				},
				{
					text: "INF",
					value: "inf",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-3.svg",
				},
				{
					text: "QUE",
					value: "que",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-4.svg",
				},
				{
					text: "NAC",
					value: "nac",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-5.svg",
				},
				{
					text: "NON",
					value: "no_n",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-6.svg",
				},
				{
					text: "VIV",
					value: "viv",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-7.svg",
				},
				{
					text: "MUE",
					value: "mue",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-8.svg",
					class: "",
				},
				{
					text: "DEP",
					value: "dep",
					visible: [1, 5].includes(this.status) ? false : true,
					image: "/lotes/lote-grid-8.svg",
					class: "s-bordered-cell",
				},
				{
					text: "Último registro",
					value: "register_by",
					visible: [3].includes(this.status) ? true : false,
					class: "s-bordered-cell",
				},
				{
					text: "CREADO POR",
					value: "creado_por",
					visible: true,
					class: "s-bordered-cell",
				},
				{
					text: "ACTIVADO EN",
					value: "active_date",
					visible: [2, 3].includes(this.status),
					class: "s-bordered-cell",
				},
				{
					text: "ACCIONES",
					value: "acciones",
					visible: [3, 4, 5].includes(this.status) || this.isInvitado ? false : true,
					class: "s-bordered-cell",
				},
				{
					text: "FINALIZAR",
					value: "finalizar",
					visible: [1, 2, 4, 5].includes(this.status) || this.isInvitado ? false : true,
					class: "s-bordered-cell",
				},
				{
					text: "FINALIZADO POR",
					value: "completedBy",
					visible: [1, 2, 3, 5].includes(this.status) ? false : true,
					class: "s-bordered-cell",
				},
				{
					text: "ANULADO POR",
					value: "canceledBy",
					visible: [1, 2, 3, 4].includes(this.status) ? false : true,
					class: "s-bordered-cell",
				},
			]
		},
		openRegisterEggsPostura(data) {
			this.dataEggs = data
			this.controllerRegisterEggsPostura = true
		},
		async updateSpecimenPostura(specimen_id, status_id, weight, index) {
			if (status_id == 1 && !(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}

			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) {
				this.hideModalUpdateStatus()
				return
				// return this.modalUpdateStatus.status == 9 && this.hideModalUpdateStatus();
			}

			let body = {
				created_by: this.currentUser.id,
				all_status: [
					{
						// alias: "NAAMA",
						// category_description: "MADRILLA",
						category_id: 2,
						check: false,
						client_id: null,
						customFormData: [],
						date_status: moment(new Date()).format("YYYY-MM-DD"),
						description: status_id == 1 ? this.modalUpdateStatus.description : `Activado desde posturas.`,
						disease_id: null,
						dob: "2020-11-10",
						evidences: [],
						// inside: "SI",
						new_status_id: status_id == 1 ? 9 : 1,
						// observations: null,
						// others_description: null,
						// photo: null,
						// plate: "516",
						pounds_weight: weight * 2.2046,
						// price: null,
						reason_death_id: null,
						responsable_id: this.currentUser.id,
						specimen_id: specimen_id,
						status_description: "ACTIVO",
						// status_id: 1,
						// thumb: null,
						// type_ouput: null,
						weight: weight,
					},
				],
				deads: JSON.stringify([]),
			}

			this.isPreloading()

			await specimensService.saveStatusMassively(body)

			// this.posturas[index].madrilla.status = status_id == 1 ? 9 : 1; //
			if (this.modalUpdateStatus.isPadrillo) {
				this.posturas[index].padrillo.status = status_id == 1 ? 9 : 1 //
			} else {
				this.posturas[index].madrilla.status = status_id == 1 ? 9 : 1 //
			}

			this.modalUpdateStatus = {
				...this.modalUpdateStatus,
				show: false,
				id: null,
				status: null,
				weight: null,
				index: null,
				user_id: null,
			}

			this.modalUpdateStatus.status == 9 && this.hideModalUpdateStatus()

			this.isPreloading(false)

			this.showToast("success", "top-right", "Posturas", "SuccessIcon", "Actualización correcta.")
		},
		async toggleUpdateStatus(madrilla_id, modal, status, index, isPadrillo = false) {
			if (status == 9) {
				//Activar al specimen
				this.modalUpdateStatus.id = madrilla_id
				this.modalUpdateStatus.show = false
				this.modalUpdateStatus.status = 9
				this.modalUpdateStatus.index = index
				this.modalUpdateStatus.isPadrillo = isPadrillo
				this.updateSpecimenPostura(madrilla_id, status, null, index)
			} else if (status == 1) {
				// Cambiar a muda
				await this.getUsers()
				this.modalUpdateStatus.id = madrilla_id
				this.modalUpdateStatus.show = modal
				this.modalUpdateStatus.status = status
				this.modalUpdateStatus.index = index
				this.modalUpdateStatus.isPadrillo = isPadrillo
				//
			}
		},
		hideModalUpdateStatus() {
			if (this.posturas[this.modalUpdateStatus.index]) {
				if (this.modalUpdateStatus.isPadrillo) {
					this.posturas[this.modalUpdateStatus.index].padrillo.checkActive =
						this.modalUpdateStatus.status == 1 ? true : false
				} else {
					this.posturas[this.modalUpdateStatus.index].madrilla.checkActive =
						this.modalUpdateStatus.status == 1 ? true : false
				}
			}

			this.modalUpdateStatus = {
				...this.modalUpdateStatus,
				show: false,
				id: null,
				status: null,
				weight: null,
				index: null,
				user_id: null,
				description: null,
			}
		},
		async getUsers() {
			const { data } = await utilitiesService.getUsersByRole()
			this.modalUpdateStatus.users = data
		},
		hideCommentModalUpdateStatus() {
			if (!this.modalUpdateStatus.show_comment) {
				this.modalUpdateStatus.description = null
			}
		},
		openModalByIdSpecimenPending(padrillo) {
			this.modalViewPosturasPending.title = `Posturas activas / ${padrillo.alias} (${padrillo.plate}) `
			this.modalViewPosturasPending.show = true
			this.modalViewPosturasPending.id = padrillo.id
		},
	},
}
</script>

<style lang="scss" scope>
.container-cintillos {
	margin-top: 0.5rem;
	display: flex;
	align-items: flex-start;
	justify-content: center;

	.cintillo {
		height: 30px;
		width: 20px;
		background: red;
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 64%, 0 100%);
		position: relative;
		margin-right: 0.5rem;

		:last-child {
			margin-right: 0;
		}

		span {
			font-weight: bold;
			position: absolute;
			left: 50%;
			translate: -50%;
			color: #fff;
		}
	}
}
.style-liberado {
	background: #f7ff4c;
	margin-top: 0.5rem;
	width: auto;
}
.title-search {
	width: 200px;
}
.icon-head-posturas {
	margin-right: 0.2rem;
}
.box-primary {
	background: #7367f0 !important;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	overflow: hidden;
	// margin: 2rem 0 !important;
	// height: 40px;
	// display: flex;
	// align-items: center;
	// padding-top: 0.7rem !important;
	// outline: #fff 2px solid !important;
	margin: 0 !important;
	// padding: 0 !important;
}
.container-checkbox {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.table-posturas-table {
	min-height: 80vh;
}
.table-posturas-table-min {
	min-height: 40vh;
}
.xt-search-input {
	display: flex;
}
.posture-b-table {
	--cell-border: #e0e0e0;
}
.col-span {
	.s-padrillo-card {
		border-right: 1px solid var(--cell-border);
		position: relative;
		&::after {
			content: "";
			width: 4px;
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			background-color: var(--primary);
		}
	}
	td {
		border-color: var(--cell-border);
	}
	&.col-even {
		background-color: rgba(208, 205, 244, 0.2);
		// background-color: #D0CDF41A;
	}
	.dark-layout &.col-even {
		background-color: #323b54;
	}
	&.col-odd {
		.s-padrillo-card::after {
			background-color: #afafaf;
		}
	}
}
.s-bordered-cell {
	border-right: 1px solid var(--cell-border);
}
.s-header-cell {
	border-spacing: 0;
}
.header-dash {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.sticky-table {
	position: sticky;
	top: 40px;
	z-index: 2;
}
.legend-postura-main {
	margin-top: 1rem;
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	@media screen and (min-width: 700px) {
		grid-template-columns: 1fr 1fr;
	}
	p {
		margin-bottom: 0;
		font-weight: bolder;
		border: 1px solid #7367f0;
		padding: 0.5rem 1rem;
		// background: #f5f5f5;
		// margin-bottom: 0.5rem;
		border-radius: 20px;
		span {
			font-weight: lighter;
		}
	}
}
</style>
